import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import {get, kebabCase} from 'lodash'
import Helmet from 'react-helmet'
import Layout from '../components/theme/layout'
import SEO from '../components/seo'
import Spinner from 'react-bootstrap/Spinner'
import { buildBreadcrumbsItem, handleInnerNavigation, getHeaderSize } from '../helpers'
import { IoMdChatboxes, IoIosMail } from "react-icons/io";
import { FaPhone } from "react-icons/fa";
import styles from '../scss/brand-transition.scss'

class BrandTransitionPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      headerHeight: 0
    }
  }
  
  componentDidMount() {
    this.setState({
      headerHeight: getHeaderSize().height
    })
  }
  
  render() {  
    const mainImage = get(this.props, 'data.mainImage.childImageSharp')
    const bitesImage = get(this.props, 'data.bitesImage.childImageSharp')
    const chewsImage = get(this.props, 'data.chewsImage.childImageSharp')
    const oilsImage = get(this.props, 'data.oilsImage.childImageSharp')
    const coconutOilImage = get(this.props, 'data.coconutOilImage.childImageSharp')
    const peanutButterImage = get(this.props, 'data.peanutButterImage.childImageSharp')
    const propertiesImage = get(this.props, 'data.propertiesImage.childImageSharp')
    const propertiesExtendedImage = get(this.props, 'data.propertiesExtendedImage.childImageSharp')
    const propertiesFullImage = get(this.props, 'data.propertiesFullImage.childImageSharp')
    
    const title = 'New Look | Honest Paws®'
    const seo = {
      title: title,
      meta: {
        title: title,
        image: mainImage.fixed,
        description: 'Honest Paws has a new look! Our product line is still the same one you know at the exact same price - just with a new look! Click here to have a sneak peek at new look'
      },
      children: [],
      disallowIndexing: true
    }    
    const breadcrumbs = [
      buildBreadcrumbsItem('New Look', this.props.location.href)
    ]
    
    
    return (
      <Layout containerClassName={`brand_transition no-breadcrumbs`} hideFooter={true} hideHeader={true} bodyClassName="chat-hidden" location={this.props.location}>
        <SEO { ...seo } />
        <section className="section-main text-center">
          <div className="container">
            <div className="row">
              <div className="col-10 offset-1 p-0">                
                <h2 className="main">Same Product Line, New Look.</h2>
                <h1>Honest Paws CBD products have sleek, new packaging!</h1>
              </div>
              <nav className="col-12">      
                <ul className="d-flex justify-content-center flex-wrap">
                  {
                    ['Bites', 'Soft Chews', 'CBD Oil', 'Peanut Butter', 'Coconut Oil'].map((label, i) => (
                      <li key={i}>
                        <a href={`#${kebabCase(label)}`} onClick={(e) => handleInnerNavigation(e, -this.state.headerHeight)}>{ label }</a>
                      </li>
                    ))
                  }
                </ul>
              </nav>
              <div className="col-12 col-sm-10  offset-sm-1">
                <Img fluid={mainImage.fluid} className="img-fluid" />
              </div>
              <div className="col-12 col-md-10 offset-md-1">
                <p>
                  Don't worry. We have made <strong>ZERO changes</strong> to your product. <br />
                  It's still the exact same products you know at the exact same price.
                </p>
                <p className="mb-md-0">
                  We wanted to give you a heads up because your <strong>next shipment will contain the new packaging.</strong><br />
                </p>
                <p className="mt-md-0">
                  We're so excited to share our new look and feel with you, a valued member of the Honest Paws pack. Expect more exciting updates in the next few weeks!
                </p>
                <p>
                  Until then, feel free to checkout out the new look of our product line below.
                </p>
              </div>
            </div>            
          </div>
        </section>
        <section className="section-products section-products-bites bg-light" id="bites">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-6">
                <Img fluid={bitesImage.fluid} className="img-fluid" />
              </div>
              <div className="col-12 col-md-6 px-4">
                <h2 className="">CBD Bites</h2>
                <p>
                  Honest Paws CBD Bites offer an ailment-specific solution to stress, physical discomfort, and seasonal allergies. These delectable bites contain CBD, provided by organic full-spectrum hemp as well as other high-quality, all natural ingredients.
                </p>
                <Img fixed={propertiesImage.fixed} className="img-fluid img-properties" />
              </div>
            </div>
          </div>
        </section>
        <section className="section-products section-products-chews bg-yellow-light" id="soft-chews">
          <div className="container">
            <div className="row flex-md-row-reverse align-items-center">
              <div className="col-12 col-md-6">
                <Img fluid={chewsImage.fluid} className="img-fluid" />
              </div>
              <div className="col-12 col-md-6 px-4">
                <h2 className="">CBD Soft Chews</h2>
                <p>
                  Honest Paws CBD Soft Chews can help boost relaxation and mobility in pets. These yummy chews contain CBD, provided by organic full-spectrum hemp, as well as other relaxation and mobility boosting ingredients. 
                </p>
                <Img fixed={propertiesImage.fixed} className="img-fluid img-properties" />
              </div>
            </div>
          </div>
        </section>
        <section className="section-products section-products-oils" id="cbd-oil">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-6">
                <Img fluid={oilsImage.fluid} className="img-fluid" />
              </div>
              <div className="col-12 col-md-6 px-4">
                <h2 className="">CBD Oil for Dogs & Cats</h2>
                <p>
                  Honest Paws Well CBD oil boosts overall wellness in your pet. It is your one-stop shop for promoting relaxation, relieving occasional discomfort, and tackling seasonal allergies.
                </p>
                <Img fixed={propertiesImage.fixed} className="img-fluid img-properties" />
              </div>
            </div>
          </div>
        </section>
        <section className="section-products section-products-peanut_button bg-light" id="peanut-butter">
          <div className="container">
            <div className="row flex-md-row-reverse align-items-center">
              <div className="col-12 col-md-6">
                <Img fluid={peanutButterImage.fluid} className="img-fluid" />
              </div>
              <div className="col-12 col-md-6 px-4">
                <h2 className="">Calm Peanut Butter</h2>
                <p>
                  Our Calm CBD Peanut Butter has a new look and a simplified recipe. It’s still the same calming peanut butter you know and love except now, we’ve removed the rapeseed oil. The reason for this is because the rapeseed oil served as a carrier oil for the CBD. However, there is more than enough MCT oil to do the job which allowed us to simplify the recipe.
                </p>
                <Img fixed={propertiesFullImage.fixed} className="img-fluid img-properties" />
              </div>
            </div>
          </div>
        </section>
        <section className="section-products section-coconut-oil bg-yellow-light" id="coconut-oil">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-6">
                <Img fluid={coconutOilImage.fluid} className="img-fluid" />
              </div>
              <div className="col-12 col-md-6 px-4">
                <h2 className="">Well Coconut Oil</h2>
                <p>
                  Well Coconut Oil is a great way to tackle seasonal allergies and skin irritation, as well as boost overall wellness in your dog. This smooth formula contains organic hemp oil with naturally occurring CBD and organic coconut oil.
                </p>
                <Img fixed={propertiesExtendedImage.fixed} className="img-fluid img-properties" />
              </div>
            </div>
          </div>
        </section>
        <section className="section-questions text-left text-md-center">          
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h3 className="pr-3">Have a question? <br className="d-md-none" />You can always reach out to our experts.</h3>
                  <p>
                    You can always chat with our team of pet nutrition experts and feel A++ about your dog’s health and wellness. If anything changes with your dog’s health, lifestyle, or tastebuds, we’re here to help make sure they have the best personal support.
                  </p>
                </div>
                <div className="col-12 col-md-4">
                  <span>
                    <IoMdChatboxes className="icon-io" alt="Chat Icon"/> Chat with us
                  </span>
                </div>                
                <div className="col-12 col-md-4">
                  <span>
                    <IoIosMail className="icon-io" alt="Email Icon"/> Email us: <a href="mailto:ask@honestpaws.com" target="_blank">ask@honestpaws.com</a>
                  </span>
                </div>                
                <div className="col-12 col-md-4">
                  <span>
                    <FaPhone className="icon-flip" alt="Phone Icon"/> Call us: <a href="tel:+18447297790">1-844-729-7790</a>
                  </span>
                </div>
              </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default BrandTransitionPage

export const BrandtransitionQuery = graphql`
  query BrandtransitionQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }      
    mainImage: file(relativePath: { eq: "brand-transition/main.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
        fluid(maxHeight: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bitesImage: file(relativePath: { eq: "brand-transition/bites.png" }) {
      childImageSharp {
        fluid(maxHeight: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    chewsImage: file(relativePath: { eq: "brand-transition/chews.png" }) {
      childImageSharp {
        fluid(maxHeight: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    oilsImage: file(relativePath: { eq: "brand-transition/oils.png" }) {
      childImageSharp {
        fluid(maxHeight: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    peanutButterImage: file(relativePath: { eq: "brand-transition/peanut-butter.png" }) {
      childImageSharp {
        fluid(maxHeight: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    coconutOilImage: file(relativePath: { eq: "brand-transition/coconut-oil.png" }) {
      childImageSharp {
        fluid(maxHeight: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    propertiesImage: file(relativePath: { eq: "brand-transition/properties.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    propertiesExtendedImage: file(relativePath: { eq: "brand-transition/properties-extended.png" }) {
      childImageSharp {
        fixed(width: 260) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    propertiesFullImage: file(relativePath: { eq: "brand-transition/properties-full.png" }) {
      childImageSharp {
        fixed(width: 310) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`